<script>
import QlkMedia from "../upload/qlk-media.vue";
export default {
  props: {
    value: Object,
    uploadModalId: {
        type: String,
        default: 'add-individual-media-modal'
    },
  },
  data() {
    return {      
    }
  },
  components: {
      QlkMedia
  },
  computed: {
    title(){
        return this.value ? 'Cambiar imagen' : 'Añadir imagen'
    }
  },
  methods: {
    showUploadModal() {
        this.$bvModal.show(this.uploadModalId);
    },
    updateValue(image) {
      // Emitir el nuevo valor al padre cuando cambia el input
      this.$emit('input', image);
    },
    deleteImage() {
        this.$emit('input', null);
    }
  }
}
</script>

<template>
  <div>
    <QlkMedia :modal-id="uploadModalId" :single="true" :selected="value" @onSuccess="updateValue"/>
    <div class="qlk-preview-item">
        <div class="qlk-preview-item-delete" v-if="value">
            <i class="ri-close-circle-fill text-danger" @click="deleteImage()" v-b-tooltip.hover title="Eliminar"></i>
        </div>
        <div class="qlk-preview-item-thumbnail" @click="showUploadModal()" v-b-tooltip.hover :title="title">
            <img :src="value.url" :alt="value.alt" v-if="value">
            <i class="mdi mdi-plus-circle font-size-18" v-if="!value"></i>
        </div>
    </div>
  </div>
</template>